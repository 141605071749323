import React from "react"
import logo from "../../imgs/logo-text.jpg"
import style from "./style.module.css"

const Footer = () => {
  return (
    <>
      <hr />
      <div className={style.container}>
        <p>
          <span className={style.mintitle}>Helyszín:</span>
          Érd, M7-es autópálya déli lehajtójától (Bem tér) pár percre
        </p>
        <img src={logo} alt="Logó" />
        <p>
          <span className={style.mintitle}>Email:</span>

          <a href="mailto:jakabjanosieszter@gmail.com">
            jakabjanosieszter@gmail.com
          </a>
          <br />
          <br />
          <span className={style.mintitle}>Telefon:</span>

          <a>Gyermekvállalás miatt ideiglenesen nem elérhető</a>
        </p>
      </div>
    </>
  )
}

export default Footer
